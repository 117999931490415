import React from "react";
import Typography from '@mui/material/Typography';

interface PageProps {
    title: string,
    width?: string,
}

const PageHeader: React.FC<PageProps> = ({ title, width = '700px' }) => {
    return (
        <Typography gutterBottom variant="h5" component="div"  style={{ display: "flex", justifyContent: "flex-start", margin: "15px 0", minWidth: width }}>
            {title}
        </Typography>
    )
}

export default PageHeader;