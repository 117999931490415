import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from "react-router-dom";

export default function BasicBreadcrumbs(props: { root?: string, primary?: string, url?: string }) {
  const navigate = useNavigate();

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, url?: string) {
    event.preventDefault();
    const URL = url || '/home';
    console.info('You clicked a breadcrumb.', URL);
    navigate(`${URL}`);
  }

  return (
    <div role="presentation" onClick={(event) => handleClick(event, props?.url)}>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to="/">
          {props.root ? props.root : 'Home'}
        </NavLink>
        <Typography color="text.primary">{props.primary}</Typography>
      </Breadcrumbs>
    </div>
  );
}
