import {
    Outlet,
  } from "react-router-dom";

import React from "react";

const LayoutPlain = () => {


  return (
    <div className="bg-white dark:bg-black" style={{minHeight: "100vh"}}>
      <Outlet />
    </div>
  );
};

export default LayoutPlain;
