import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";

// Parasail imports
import { useUserContext } from "components/providers/UserContext";

interface Props {}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
  };
const Feedback: React.FC<Props> = () => {
    const [openModal, setOpenModal] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const { UserContextService } = useUserContext();
    const [username, setUsername] = React.useState<string | undefined>('');
    const [accountName, setAccountName] = React.useState<string | undefined>('');

    React.useEffect(() => {
      if(UserContextService.userState && UserContextService.userState.user){
        setUsername(UserContextService.userState.user.username);
      }
    }, [
      UserContextService.userState,
      UserContextService.userState.user, 
      UserContextService.userState.user.username
    ]);

    React.useEffect(() => {
      if(UserContextService.userState && UserContextService.userState.activeAccount){
        setAccountName(UserContextService.userState.activeAccount.accountName);
      }
    }, [
      UserContextService.userState,
      UserContextService.userState.activeAccount, 
      UserContextService.userState.activeAccount.accountName
    ]);

    const handleEmailSend = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const subject = `Feedback from ${username} - organization ${UserContextService.userState.activeAccount?.accountName}`;
        const currentUrl = window.location.href;
        const newMessage = message.replace(/\n/g, '%20 %0D%0A') + ' %0D%0A%0D%0A -----%0D%0A%20refUrl:%20' + currentUrl;
        console.log('email message: ', newMessage);
        window.location.href = `mailto:support@parasail.io?subject=${subject}&body=${newMessage}`;
        e.preventDefault();
    };
        
    return (
    <>
      <Button 
        variant="contained" 
        onClick={handleOpen}
        style={{
          position: "fixed",
          top: "30%",
          right: "0",
          zIndex: "105",
          transform: "rotate(270deg)",
          transformOrigin: "right bottom",
          textTransform :"none"
        }}
        size="small"
      >
        Send Feedback
      </Button>

      <Link
          to='#'
          onClick={handleOpen}
      >
        Feedback
      </Link>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="bg-white">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Feedback
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We really appreciate your feedback
          </Typography>
          <div className="w-full max-w-lg">
            <form className="bg-white px-2 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Organization
                </label>
                {accountName}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Username
                </label>
                {username}
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Message
                </label>
                <textarea 
                    style={{height: "150px"}} 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
                    id="message" 
                    placeholder="Message here..." 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between">
                <Button variant="outlined" onClick={handleClose} type="button">
                  Close
                </Button>
                <Link
                    to='#'
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleEmailSend}
                >
                    Send
                </Link>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
    );
}

export default Feedback;