import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Input from '@mui/material/Input';

// Parasail imports
import {  FetchCommonAPI } from "service/FetchService";
import { Invite } from "react-app-env";

const InviteUsageView = () => {
  const [inviteCode, setInviteCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openFailed, setOpenFailed] = React.useState(false);

  const navigate = useNavigate();

  const handleApplyInviteCode = () => {
    console.log('handleDeployDesign', inviteCode);
    if(inviteCode !== '') {
      setLoading(true);

      const invite: Invite = {
        inviteCode
      };

      // Set Invite Code
      FetchCommonAPI.setInvite(invite).then((response) => {
        console.log('setInvite code response', response);
        setLoading(false);
        setOpenSuccess(true);
        if(response) {
          console.log('setInvite code succeeded. Routing back to Home to show marketing content.', response);
          navigate('/home');
        }
      }).catch((e) => {
        console.log(e);
        setOpenFailed(true);
        setLoading(false);
        //reset invite input
        setInviteCode('');
      });
    }
  }

  const handleCloseSuccessSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseFailedSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenFailed(false);
  };

  return (
    <>
      <div style={{minHeight: "520px", display: "flex", alignItems: "stretch", justifyContent: "center"}}>
        <Box /*className="w-2/6"*/ style={{ width: "460px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Grid container spacing={4} className="p-4 bg-gray-100 dark:bg-gray-800"  sx={{ border: "1px solid #ddd"}}>
            <Grid size={{ xs: 12 }} style={{paddingTop: "0", paddingLeft: "0"}}>
              <span className='text-gray-800 dark:text-gray-100 font-bold' style={{fontSize: "2em"}}>Use invite code</span>
            </Grid>
            <Grid size={{ xs: 12 }} style={{paddingTop: "0", paddingLeft: "0"}}>
              <div className="container mx-auto rounded py-1">
                <div className="xl:w-full border-b border-gray-300 dark:border-gray-700 py-2">
                    <div className=" w-11/12 xl:w-full xl:mx-0 items-center">
                        <p className="text-lg text-gray-800 dark:text-gray-100 font-bold">Enter your invite to join an existing organization</p>
                    </div>
                </div>
              </div>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <div className="container">
                <form className="w-12/12 xl:w-full xl:mx-0">
                  {/* Model Base */}
                  <div className="flex flex-col">
                      {/* <label 
                        htmlFor="inviteCode" 
                        className="self-start pb-2 text-lg text-gray-800 dark:text-gray-100"
                      >
                        Invite Code
                      </label> */}
                      <Input 
                        tabIndex={0}
                        autoFocus={true}
                        type="text" 
                        id="inviteCode" 
                        name="inviteCode"
                        margin='dense'
                        color='primary'
                        required
                        fullWidth={false}
                        className="w-2/6 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-lg focus:outline-none focus:border-indigo-700 placeholder-gray-500" 
                        placeholder="Enter or paste the invite code here"
                        value={inviteCode}
                        style={{minWidth: "400px"}}
                        onChange={(e) => setInviteCode(e.target.value)}
                      />
                  </div>
                  {/* End Model Base */}
                </form>
              </div>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Stack spacing={2} direction="row" style={{display: "flex", justifyContent: "flex-end"}}>
                <LoadingButton 
                  variant="contained" 
                  onClick={handleApplyInviteCode} 
                  loading={loading}
                  loadingIndicator="Applying invite..."
                >
                  Apply
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccessSnackBar}>
        <Alert
          onClose={handleCloseSuccessSnackBar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Invite code accepted!
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} onClose={handleCloseFailedSnackBar}>
        <Alert
          onClose={handleCloseFailedSnackBar}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Looks like something went wrong with your invite code.
        </Alert>
      </Snackbar>
    </>
  );
};

export default InviteUsageView;