
import * as React from 'react';
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import ReactGA from "react-ga4";
import Chip from '@mui/material/Chip';

// Parasail imports
import { useUserContext } from "components/providers/UserContext";
import InviteUsageView from './InviteUsageView';
import { List, ListItem } from '@mui/material';
import { FetchCommonAPI } from 'service/FetchService';

const UserProfileView = () => {
  const { UserContextService } = useUserContext();

  React.useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/userprofile", title: "user profile page" });
  }, []);

  return (
    <>
      <BasicBreadcrumbs primary="My Profile" />

      <PageHeader title="My Profile" />
      <div
        style={{
          minHeight: "100%",
          display: "block",
          justifyContent: "center",
        }}
      >
        <p
          className="text-gray-800 dark:text-gray-100 font-bold"
          style={{ fontSize: "2em" }}
        >
          {UserContextService.userState.user.username && `Hello, ${UserContextService.userState.user.username}`}
        </p>

        <p className="text-gray-800 dark:text-gray-100 mt-1">
          Your active organization is&nbsp;
          <strong>{UserContextService.userState.activeAccount?.accountName}</strong>
        </p>

        <p className="text-gray-800 dark:text-gray-100 mt-3">
          You're a member of the following organizations:
        </p>

        <List>
          {UserContextService.userState.accounts.map((a) => (
            <ListItem>
              <Chip label={a.accountName} onClick={() => FetchCommonAPI.setAccount(a.accountName || '').then(() => UserContextService.setActiveAccount(a))} />
            </ListItem>
          ))}
        </List>

        <InviteUsageView />
      </div>
    </>
  );
};

export default UserProfileView;