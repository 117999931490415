import React from 'react';
import { Line } from '@ant-design/plots';
import { DateRangeType } from "react-tailwindcss-datepicker";
import useMediaQuery from '@mui/material/useMediaQuery';

import {  FetchBillingAPI } from "service/FetchService";
import { BillingServerlessItemType, BillingServerlessItems } from "react-app-env";
import moment from "moment";

interface Props {
  dateRange: DateRangeType,
  type: string
}

const dedupStr = (data: Array<BillingServerlessItemType>) => {
  const result = data?.map(d => d.name).reduce((acc, curr) => {
    if (!acc.includes(String(curr))) {
      acc.push(curr.toString());
    }
    return acc;
  }, [] as String[]);
  return result;
}

const BillingOverviewBreakdownChart: React.FC<Props> = ({dateRange, type}) => {
  const [ keysData, setKeysData ] = React.useState(Array<BillingServerlessItemType>);
  const [ deploymentsData, setDeploymentsData ] = React.useState(Array<BillingServerlessItemType>);
  const [ configsContext, setConfigsContext ] = React.useState<object>({});

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  React.useEffect(() => {
    const interval = 3600000 * 24; // day
    const {startDate, endDate} = dateRange;
    const startDateFormatted = startDate?.toISOString() ? +Date.parse(startDate?.toISOString()) : 0;
    const endDateFormatted = endDate?.toISOString() ? +Date.parse(endDate?.toISOString()) : Date.now();

    console.log('dateRange: ', dateRange)
    // Fetch serverless keys w/ interval
    FetchBillingAPI.getBillingAllServerlessKeysByTimeRange(startDateFormatted, endDateFormatted, interval).then((response: BillingServerlessItems) => {
      console.log('getBillingAllServerlessKeysByTimeRange response.items', response.items);
      if(response && response.items.length !== 0) {
        setKeysData(response.items);
      } else {
        setKeysData([]);
      }
    }).catch((e: any) => {
        console.log(e);
    });

    // Fetch serverless deployments w/ interval
    FetchBillingAPI.getBillingAllServerlessDeploymentsByTimeRange(startDateFormatted, endDateFormatted, interval).then((response: BillingServerlessItems) => {
        console.log('getBillingAllServerlessDeploymentsByTimeRange response', response);
        if(response && response.items.length !== 0) {
          setDeploymentsData(response.items);
        } else {
          setDeploymentsData([]);
      }
    }).catch((e: any) => {
        console.log(e);
    });
  }, [dateRange, dateRange.startDate, dateRange.endDate, type]);

  React.useEffect(() => {
    setConfigsContext({
      data: type === 'keys' ? keysData : deploymentsData,
      point: {
        shapeField: "square",
        sizeField: 4,
      },
      style: {
        lineWidth: 2,
        // lineDash: (data: any) => {
        //   if (data[0].name === 'shared1') return [4, 4];
        // },
        // opacity: (data: any) => {
        //   if (data[0].name === 'shared1') return 0.5;
        // },
      },
      // interaction: {
      //   tooltip: {
      //     marker: false,
      //   },
      // },
      // tooltip: { channel: 'y' },
    //   tooltip: {
    //     domStyles: {'g2-tooltip-title':{display:'none'}},
    //     formatter: (x:number,y:number) => {return {name: moment.unix(parseInt(x)).format(format), value: y } }
    // }};
      scale: {
        y: { 
          nice: true,
          type: 'linear',
          domain: [0, 10000],
          // range: [0.01, 1],
        },
        color: {
          domain: dedupStr(type === 'keys' ? keysData : deploymentsData),
        },
      },
      xField: (item: any) => moment(new Date(item.timestamp)).calendar(), //'timestamp',
      yField: (item: any) => item.cost < .01 ? 0 : `$${item.cost.toFixed(2)}`,//"cost",
      // sizeField: "cost",
      colorField: "name",
      connectNulls: {
        connect: true,
        connectStroke: '#aaa',
      },
      theme: prefersDarkMode ? 'classicDark' : 'classic',
      // legend: { 
      //   size: false
      // },
      axis: {
        x: { title: 'Time' },
        y: { title: 'Cost' },
      },
      yAxis: {
        visible: true,
        min: 0
      },
    });

  }, [keysData, deploymentsData, type, prefersDarkMode]);

  // console.log('data?', data)
  return (
    <div style={{minWidth: "400px", minHeight: "540px"}}>
      {/* <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-2 mb-2 dark:text-gray-800">
        Estimated interactive inference performance of <span className="font-semibold text-gray-900">{designName}</span>
      </div> */}
      <Line {...configsContext}/>
    </div>
  );
};

export default BillingOverviewBreakdownChart;