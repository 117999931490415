import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
// import {
//   GridColDef,
//   GridRenderCellParams
// } from '@mui/x-data-grid';
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import Link from '@mui/material/Link';
import ReactGA from "react-ga4";

const ManagedHomeView = () => {
  React.useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/managed", title: "managed dashboard page" });
  }, []);

  return (
    <>
      <BasicBreadcrumbs root="Home" primary="Managed Enterprise" url="/home" />

      <PageHeader title="Managed Enterprise" />
      
      <div className="bg-white dark:bg-black" style={{/*minHeight: "820px",*/ display: "flex", alignItems: "stretch", justifyContent: "center"}}>
        <Box className="w-full" style={{marginBottom: "25px"}}>
          <Grid container spacing={2}>
            {/* model list */}
            <Grid size={{ xs: 6, md: 12 }}>
              {/* <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                <Button variant="contained" disabled>
                  <NavLink to="/dedicated/new">
                    Create managed model
                  </NavLink>
                </Button>
              </Stack> */}
              <Link target="_blank" variant="h5" href="mailto:hello@parasail.io" underline="always" sx={{'&:hover': {cursor: 'pointer'}}}>
                Contact Us
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default ManagedHomeView;