import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Drafts from '@mui/icons-material/Drafts';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Parasail imports
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import {  FetchCommonAPI } from "service/FetchService";
import { InviteRequest, UserInvite } from "react-app-env";
import { useUserContext } from "components/providers/UserContext";
import { CopyBlock } from 'react-code-blocks';
import ReactGA from "react-ga4";

const InviteCreateView = () => {
  const [inviteCode, setInviteCode] = React.useState('');
  const [userInvite, setUserInvite] = React.useState<UserInvite>(Object);
  const [loading, setLoading] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [accountName, setAccountName] = React.useState('');

  const { UserContextService } = useUserContext();

  React.useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/invite", title: "create invite page" });
  }, []);

  React.useEffect(() => {
    if(!UserContextService.userState.isAdmin) {
      setAccountName(UserContextService.userState.activeAccount.accountName?.toLocaleLowerCase() || '');
    }
  }, [UserContextService.userState.isAdmin, UserContextService.userState.activeAccount.accountName]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };
  // const navigate = useNavigate();

  const handleGenerateInviteCode = () => {
    console.log('handleGenerateInviteCode');
    if (!accountName) {
        return;
    }

    setLoading(true);

    const inviteRequest: InviteRequest = {
      accountName
    };

    // Set Invite Code
    FetchCommonAPI.createInvite(inviteRequest).then((response) => {
      console.log('createInvite code response', response, inviteRequest);
      setLoading(false);
      if(response) {
        //ex. response = {"id":2,"inviteCode":"b3b94eb1-42d5-4f6e-977f-dff23bdb20ba","createdAt":1717464472810,"expiresAt":1718069272810,"email":null,"redirectUrl":null,"used":false,"usedAt":0}
        setInviteCode(response?.inviteCode as string);
        setUserInvite(response);
        //   navigate('/home');
      }
    }).catch((e) => {
      console.log('Invite generate fetch error: ', e.code);
      setOpenToast(true);
    });
  }

  const handleAccountNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    console.log('handleAccountNameChange: ', e.currentTarget.value);
    setAccountName(e.currentTarget.value);
  }

  const makeInviteUrl = (inviteCode: string) => {
    return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/i/" + inviteCode;
  }

  return (
    <>
      <BasicBreadcrumbs root="Home" primary="Manage Invite" url="/home" />

      <div style={{minHeight: "520px", display: "flex", alignItems: "stretch", justifyContent: "center"}}>
        <Box /*className="w-2/6"*/ style={{ width: "600px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Grid container spacing={4} className="p-4 bg-gray-100 dark:bg-gray-800"  sx={{ border: "1px solid #ddd"}}>
            <Grid size={{ xs: 12 }} style={{paddingTop: "0", paddingLeft: "0"}}>
              <span className='text-gray-800 dark:text-gray-100 font-bold' style={{fontSize: "2em"}}>Manage Invites</span>
            </Grid>
            {UserContextService.userState.hasActiveAccount &&
              <Grid size={{ xs: 12 }}style={{paddingTop: "0", paddingLeft: "0"}}>
                <div className="container mx-auto rounded py-1">
                  <div className="xl:w-full border-b border-gray-300 dark:border-gray-700 py-2">
                      <div className="w-11/12 xl:w-full xl:mx-0 items-center">
                        <p className="text-lg text-gray-800 dark:text-gray-100">
                          Create invite codes for&nbsp;
                          { UserContextService.userState.isAdmin ?
                            (<>
                                <input
                                  type="test"
                                  id="accountName"
                                  name="accountName"
                                  required
                                  className="border bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 "
                                  value={accountName}
                                  onChange={handleAccountNameChange} />
                                  {accountName === 'parasail' && (
                                    <Alert
                                      severity="warning"
                                      sx={{ width: '100%' }}
                                      className='mt-3 text-gray-800 dark:text-gray-100' style={{fontSize: ".8em"}}
                                    >
                                      You're creating an invite for the parasail admin group. Use responsibly.
                                    </Alert>
                                  )}
                              </>)
                          : (<span className="font-bold">{UserContextService.userState.activeAccount?.accountName}!</span>)}
                        </p>
                      </div>
                  </div>
                </div>
              </Grid>
            }

            <Grid size={{ xs: 12 }} style={{ paddingLeft: "0", alignContent: "center" }}>
              {UserContextService.userState.hasActiveAccount ? (
                <LoadingButton
                  variant="contained"
                  onClick={handleGenerateInviteCode}
                  loading={loading}
                  loadingIndicator="Generating invite..."
                  endIcon={<Drafts />}
                >
                  Generate Invite Code
                </LoadingButton>
              ) : 'You are not assigned to an account.'}
            </Grid>

            <Grid size={{ xs: 12 }} style={{ paddingLeft: "0", alignContent: "center" }}>
              {userInvite && Object.keys(inviteCode).length !== 0 ? (
                <Grid container>
                  <Grid size={{ xs: 12, md: 3 }} sx={{minWidth: '100%'}}>  
                    <CopyBlock
                      text={makeInviteUrl(userInvite.inviteCode || "")}
                      language={"plaintext"}
                      showLineNumbers={false}
                      wrapLongLines={true}
                    />
                  </Grid>
                </Grid>
              ) : UserContextService.userState.hasActiveAccount ? <div className="mt-2 mb-2">Click the <span className="font-semibold px-1">Generate Invite Code</span> button above to get invite code.</div> : ''}
            </Grid>
          </Grid>
        </Box>

        {/* <InviteUsageView /> */}
      </div>

      {/* Shows an error toast when something failed on fetch */}
      <Snackbar open={openToast} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Failed to get invite code. Check network or login.
        </Alert>
      </Snackbar>
    </>
  );
};

export default InviteCreateView;