import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
//https://chatscope.io/storybook/react/?path=/story/components-message--with-header
import * as React from 'react';
import Grid from '@mui/material/Grid2';
import { memo } from "react";
import Alert from '@mui/material/Alert';
// import type { ReactNode } from "react";
import {
  Thread,
  ThreadWelcome,
  Composer,
  AssistantMessage,
  type ThreadConfig,
  // useEdgeRuntime,
  useMessageContext,
  BranchPicker,
  AssistantActionBar,
  useSwitchToNewThread
} from "@assistant-ui/react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Button from '@mui/material/Button';
import { RuntimeProvider } from "components/providers/RuntimeProvider";
import { ServerlessDeployment, DedicatedDeployment } from "react-app-env";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

// const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: "none",
//   },
// });

interface Props { 
    data: ServerlessDeployment | DedicatedDeployment, 
    // children?: ReactNode
}
type CustomMetadata = {
  usage: {
    promptTokens: number;
    completionTokens: number;
  };
  ttft: number | null;
  tps: number | null;
}

const UsageInformation = () => {
  const { useMessage } = useMessageContext();
  const { usage, ttft, tps } = useMessage((m) =>
    m.message.role === "assistant"
      ? m.message.metadata?.custom as CustomMetadata
      : undefined,
  ) ?? {};

  if (!usage) return null;

  const displayTPS = tps && tps > 500 ? '> 500' : tps?.toFixed(0);

  return (
    <div className="flex items-center gap-2" style={{padding: "5px"}}>
      <div className="text-xs text-muted-foreground">
        Input Tokens: {usage?.promptTokens}
      </div>
      <div className="text-xs text-muted-foreground">
        Output Tokens: {usage?.completionTokens}
      </div>
      {ttft &&
        <div className="text-xs text-muted-foreground">
          <CustomWidthTooltip style={{cursor: 'pointer'}} title="Time to First Token is the how long the user needs to wait before seeing the start of the model's response. The number we report here includes round-trip network time">
            <span>TTFT: {ttft?.toFixed(0)} ms</span>
          </CustomWidthTooltip>
        </div>
      }
      {tps &&
        <div className="text-xs text-muted-foreground">
          <CustomWidthTooltip style={{cursor: 'pointer'}}  title="Tokens Per Second (TPS) is how many output tokens the model can generate each second.">
            <span>TPS: {displayTPS}</span>
          </CustomWidthTooltip>
        </div>
      }
    </div>
  );
};

const NewChat: React.FC = () => {
  const switchToNewThread = useSwitchToNewThread();

  return (
    <Button variant="outlined" size="small" onClick={switchToNewThread}>
      Reset <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-2 lucide lucide-square-pen size-4"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z"></path></svg>
    </Button>
  );
};

const MyAssistantActionBar: React.FC = () => {
  return (
    <AssistantActionBar.Root
      hideWhenRunning
      autohide="not-last"
      autohideFloat="single-branch"
    >
      <UsageInformation />
      <AssistantActionBar.Reload />
      <AssistantActionBar.Copy />
      <NewChat />
    </AssistantActionBar.Root>
  );
};

const MyThread: React.FC<ThreadConfig> = (config) => {
  return (
    <Thread.Root config={config}>
      <Thread.Viewport>
        <ThreadWelcome />
        <Thread.Messages components={{ AssistantMessage: MyAssistantMessage }} />
        <Thread.ViewportFooter>
          <Thread.ScrollToBottom />
          <Composer />
        </Thread.ViewportFooter>
      </Thread.Viewport>
    </Thread.Root>
  );
};

const MyAssistantMessage: React.FC = () => {
  return (
    <AssistantMessage.Root>
      <AssistantMessage.Avatar />
      <AssistantMessage.Content />
      {/* <div className="aui-assistant-message-content"> */}
        {/* <UsageInformation /> */}
      {/* </div> */}
      <BranchPicker />
      {/* <AssistantActionBar /> */}
      <MyAssistantActionBar />
    </AssistantMessage.Root>
  );
};



const ChatPaneStream: React.FC<Props> = ({data/*, children*/}) => {
  const [chatData, setChatData] = React.useState(Object);

  React.useEffect(() => {
    setChatData(data);
  }, [data]);

  console.log('ChatPaneStream chatData: ', chatData);

  // const edgeRuntime = useEdgeRuntime({
  //   api: '/app/v1/',
  //   body: {
  //     baseURL: chatData.baseUrl, 
  //     apiKey: chatData.defaultApiKey
  //   }
  // });

  return (
    <>
     {chatData != null ?
        <>
          <Grid container spacing={1}>
            {/* Header */}
            <Grid size={{ xs: 12 }} style={{display: "flex", justifyContent: "space-between"}}>
              <strong> Chat for {chatData?.deploymentName}</strong>
            </Grid>

            {/* Chat box */}
            {chatData?.status?.status !== 'OFFLINE' ?
              <Grid size={{ xs: 12 }} style={{minHeight: "500px"}}>
                <div style={{  maxHeight: "500px", position: "relative", height: "100%" }}>
                {/* key={chatData.id} is needed this will cause react to throw away the old state when the key changes */}
                  <RuntimeProvider chatData={chatData} key={chatData.id}>
                    <MyThread welcome={{ suggestions: [{ prompt: "write me a 5 paragraph Sci-fi short story" },{ prompt: "Tell me 10 dad jokes",  }]}}/>
                    {/* <MyThread runtime={edgeRuntime} /> */}
                    {/* <Threads /> */}
                  </RuntimeProvider>
                </div>
              </Grid>
            :
            <Grid size={{ xs: 12 }} style={{minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Alert severity="info" style={{maxWidth: "520px"}}>
                <p>{` Chat is not available. Model '${chatData?.deploymentName}' is currently ${chatData?.status?.status} `}.</p>
              </Alert> 
            </Grid>
            }
          </Grid>
        </>
      : <>Please make a selection on the right.</> }
    </>
  );
}

// Only rerender if the deployment id is not the same
export default memo(ChatPaneStream, (prevProps: any, currentProps: any) => {
    return prevProps.data.id === currentProps.data.id;
  }
);