import React from 'react';
import { Line } from '@ant-design/plots';

interface Props {
  designName: string,
  dataTPS: { batchSizes: number; values: number; contextSize: string; }[],
  dataCost: { batchSizes: number; values: number; contextSize: string; }[]
  tpsContext: { batchSizes: number; values: number; contextSize: string; }[]
}

const dedup = (data: { contextSize: string; }[]) => {
  const result = data?.map(d => Number(d.contextSize)).sort().reduce((acc, curr) => {
    if (acc.length === 0 || acc[acc.length - 1] !== curr.toString()) {
      acc.push(curr.toString());
    }
    return acc;
  }, [] as String[]);
  return result;
}

const dedupStr = (data: { contextSize: string; }[]) => {
  const result = data?.map(d => String(d.contextSize)).reduce((acc, curr) => {
    if (acc.length === 0 || acc[acc.length - 1] !== curr.toString()) {
      acc.push(curr.toString());
    }
    return acc;
  }, [] as String[]);
  return result;
}

const ModelCompareChartPane: React.FC<Props> = ({designName, dataTPS, dataCost, tpsContext}) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const configsTPS = {
    data: dataTPS,
    point: {
      shapeField: 'square',
      sizeField: 4,
    },
    style: {
      lineWidth: 2,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    scale: {
      y: { nice: true },
      color: {
        domain: dedup(dataCost),
      },
    },
    xField: 'batchSizes',
    yField: 'values',
    sizeField: 'values',
    colorField: 'contextSize',
    legend: { size: false },
    axis: {
      x: { title: 'Batch Size' },
      y: { title: 'Output Tokens/Sec' },
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const configsCost = {
    data: dataCost,
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    style: {
      lineWidth: 2,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    scale: {
      y: { nice: true },
      color: {
        domain: dedup(dataTPS),
      },
    },
    xField: "batchSizes",
    yField: "values",
    sizeField: "values",
    colorField: "contextSize",
    legend: { size: false },
    axis: {
      x: { title: "Batch Size" },
      y: { title: "$/1M output tokens" },
    },
  };
  const configsTpsContext = {
    data: tpsContext,
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    style: {
      lineWidth: 2,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    scale: {
      y: { nice: true },
      color: {
        domain: dedupStr(tpsContext),
      },
    },
    xField: "batchSizes",
    yField: "values",
    sizeField: "values",
    colorField: "contextSize",
    legend: { size: false },
    axis: {
      x: { title: 'Context Size' },
      y: { title: 'Output Tokens/Sec (per request)' },
    },
  };
  
  console.log('tpsContext??', tpsContext)
  return (
    <div style={{minWidth: "400px"}}>
      <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-2 mb-2 dark:text-gray-800">
        Estimated interactive inference performance of <span className="font-semibold text-gray-900">{designName}</span>
      </div>
      {/*<Line {...configsTPS} />*/}
      {/*<Line {...configsCost} />*/}
      <Line {...configsTpsContext} />
    </div>
  );
};

export default ModelCompareChartPane;